import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type ReceptionNumberChipProps = {
  receptionNumber: string
}

export function ReceptionNumberChip({ receptionNumber }: ReceptionNumberChipProps) {
  const { t } = useTranslation()

  return <Chip label={`${t('Reception Number')} ${receptionNumber}`} />
}
