import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { forwardRef, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { DialogProps } from '../types'
import { useTranslation } from 'react-i18next'
import { DefaultCard } from '../../Cards'
import * as Texts from '../../Texts'

export const CheckedInDialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      consultation,
      receipt,
      children,
      handleToggleBlocked,
      handleDownloadReceipt,
      handleDispatchConsultationAction,
      handleReserveWithImpersonation,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()

    const { patient } = consultation

    const [open, setOpen] = useState(false)

    const handleClick = () => setOpen(true)

    const handleClose = () => setOpen(false)

    return (
      <div {...props} ref={ref}>
        {children({ handleClick })}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle mt={4}>
            <DefaultCard consultation={consultation} component={Box} />
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() =>
                handleReserveWithImpersonation({
                  patientUUID: patient.patientUUID,
                })
              }
            >
              {t('ReserveWithImpersonation')}
            </Button>
            <Button
              onClick={() =>
                handleToggleBlocked({
                  consultationID: consultation.consultationID,
                  blocked: !consultation.blocked,
                })
              }
            >
              {consultation.blocked ? t('Unblock') : t('Block')}
            </Button>
          </DialogActions>
          <DialogContent>
            <Typography>{patient.fullName}</Typography>
            <Texts.CreatedAt createdAt={consultation.createdAt} />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
)
