import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

export type ReservationTimeProps = {
  reservation?: {
    reservationDatetime: string
  }
}

export function ReservationTime({ reservation }: ReservationTimeProps) {
  const { t } = useTranslation()

  if (!reservation) {
    return null
  }

  return (
    <Typography component="span">
      {t('Reservation Time')} {format(parseISO(reservation.reservationDatetime), 'HH:mm')}
    </Typography>
  )
}
