import { components } from './schema'
import { Consultation, ConsultationCollection } from '../modules/ops/types'
import { Clinic } from '../modules/common/types'
import { Page, PrintDevice, PrintQueue } from '../modules/admin/types'

export const transformConsultationsReponse = (
  data: components['schemas']['ConsultationsResponse']
): ConsultationCollection => {
  return {
    entries: data.entries.map(transformConsultationReponse),
  }
}

export const transformConsultationReponse = (
  data: components['schemas']['ConsultationResponse']
): Consultation => {
  return {
    consultationID: data.consultation_id,
    receptionNumber: data.reception_number,
    invoiceNumber: data.invoice_number,
    insuranceCombinationNumber: data.insurance_combination_number,
    clinic: {
      clinicID: data.clinic.clinic_id,
      displayName: data.clinic.display_name,
    },
    patient: {
      patientID: data.patient.patient_id,
      patientUUID: data.patient.patient_uuid,
      parentPatientUUID: data.patient.parent_patient_uuid,
      fullName: data.patient.full_name,
      fullNameKana: data.patient.full_name_kana,
      birthday: data.patient.birthday,
      gender: data.patient.gender,
      hasLineAccount: data.patient.has_line_account,
      hasSokushin: data.patient.has_sokushin,
    },
    physician: data.physician
      ? {
          physicianID: data.physician.physician_id,
          fullName: data.physician.full_name,
        }
      : undefined,
    reservation: data.reservation
      ? {
          reservationDatetime: data.reservation.reservation_datetime,
        }
      : undefined,
    departmentCode: data.department_code,
    lastConsultation:
      data.last_consultation != null
        ? {
            consultationID: data.last_consultation.consultation_id,
            date: data.last_consultation.date,
            clinicDisplayName: data.last_consultation.clinic_display_name,
          }
        : undefined,
    interviewForm:
      data.interview_form != null
        ? {
            response: data.interview_form.response,
          }
        : undefined,
    status: data.status,
    blocked: data.blocked,
    blockedReason: data.blocked_reason,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  }
}

export const transformStaffClinicsResponse = (
  data: components['schemas']['StaffClinicsResponse']
): {
  count: number
  offset: number
  entries: Array<Clinic>
} => {
  return {
    count: data.count,
    offset: data.offset,
    entries: data.entries.map(transformStaffClinicResponse),
  }
}

export const transformStaffClinicResponse = (
  data: components['schemas']['StaffClinicResponse']
): Clinic => {
  return {
    clinicID: data.clinic_id,
    displayName: data.display_name,
    address: data.address,
    access: data.access,
    phoneNumber: data.phone_number,
    businessHours: data.business_hours,
    reservationDepartmentURL: data.reservation_department_url,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  }
}

export const transformPrintDeviceResponse = (
  data: components['schemas']['PrintDeviceResponse']
): PrintDevice => {
  return {
    clinicID: data.clinic_id,
    clinicName: data.clinic_name,
    macAddress: data.mac_address,
    queueID: data.queue_id,
    printingParams: data.printing_params,
    dotWidth: data.dot_width,
    status: data.status || undefined,
    clientType: data.client_type || undefined,
    clientVersion: data.client_version || undefined,
    printing: data.printing || undefined,
    lastPoll: data.last_poll || undefined,
  }
}

export const transformPrintDevicesResponse = (
  data: components['schemas']['PrintDevicesResponse']
): Page<PrintDevice> => {
  return {
    count: data.count,
    offset: data.offset,
    entries: data.entries.map(transformPrintDeviceResponse),
  }
}

export const transformPrintQueueResponse = (
  data: components['schemas']['PrintQueueResponse']
): PrintQueue => {
  return {
    id: data.id,
    name: data.name,
    position: data.position,
    designTemplate: data.design_template,
  }
}

export const transformPrintQueuesResponse = (
  data: components['schemas']['PrintQueuesResponse']
): Page<PrintQueue> => {
  return {
    count: data.count,
    offset: data.offset,
    entries: data.entries.map(transformPrintQueueResponse),
  }
}
