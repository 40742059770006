import React, { ComponentType } from 'react'
import { Box, GridProps, Grid, Paper, Typography, Stack } from '@mui/material'
import { Consultation } from '../../types'
import { birthdayInImperialYear, birthdayToAge } from '../../../common/utils/patient'
import {
  BlockedChip,
  FamilyChip,
  GenderChip,
  InsuranceChip,
  InterviewFormChip,
  InvoiceNumberChip,
  LastConsultationChip,
  NoLineAccountChip,
  SokushinChip,
} from '../Chips'
import * as Texts from '../Texts'

export type ReservationCardProps = GridProps & {
  onClick?: () => void
  consultation: Consultation
  component?: ComponentType<'div'>
}

export const ReservationCard = React.memo(
  ({ onClick, consultation, component = Paper }: ReservationCardProps) => {
    const { patient, departmentCode } = consultation

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="streatch"
        onClick={onClick}
        component={component}
        sx={{ p: 2 }}
        columns={10}
      >
        <Grid
          item
          xs={2}
          direction="column"
          spacing={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Stack direction="column" spacing={1}>
            <Box>
              <Stack direction="row" spacing={2}>
                <Texts.Department departmentCode={departmentCode} />
                <Texts.Physician physician={consultation.physician} />
              </Stack>
            </Box>
            <Texts.ReservationTime reservation={consultation.reservation} />
          </Stack>
        </Grid>
        <Grid item xs container>
          <Grid
            xs={12}
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid xs item>
              <Stack direction="row" spacing={2}>
                <Texts.PatientID patientID={patient.patientID} />
                <GenderChip gender={consultation.patient.gender} />
              </Stack>
            </Grid>
            <Grid
              xs
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Grid item sx={{ pr: 1 }}>
                <Texts.UpdatedAt updatedAt={consultation.updatedAt} />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item container direction="row" alignItems="flex-end">
            <Grid xs={4} item>
              <Typography variant="caption" fontWeight="bold">
                {patient.fullNameKana}
              </Typography>
              <Typography variant="h3" fontWeight="bold">
                {patient.fullName}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography fontSize="h4">{birthdayToAge(patient.birthday)} 歳</Typography>
              <Typography fontSize="h4">{birthdayInImperialYear(patient.birthday)}</Typography>
            </Grid>
            <Grid xs={4} item>
              <Grid container spacing={1}>
                {!patient.hasLineAccount && (
                  <Grid item>
                    <NoLineAccountChip />
                  </Grid>
                )}
                {patient.hasSokushin && (
                  <Grid item>
                    <SokushinChip />
                  </Grid>
                )}
                <Grid item>
                  <LastConsultationChip lastConsultation={consultation.lastConsultation} />
                </Grid>
                {consultation.interviewForm && (
                  <Grid item>
                    <InterviewFormChip />
                  </Grid>
                )}
                {consultation.blocked && (
                  <Grid item>
                    <BlockedChip />
                  </Grid>
                )}
                {consultation.patient.parentPatientUUID && (
                  <Grid item>
                    <FamilyChip />
                  </Grid>
                )}
                {consultation.insuranceCombinationNumber && (
                  <Grid item>
                    <InsuranceChip
                      insuranceCombinationNumber={consultation.insuranceCombinationNumber}
                    />
                  </Grid>
                )}
                {consultation.invoiceNumber && (
                  <Grid item>
                    <InvoiceNumberChip invoiceNumber={consultation.invoiceNumber} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)
