import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

export type CreatedAtProps = {
  createdAt: string
}

export function CreatedAt({ createdAt }: CreatedAtProps) {
  const { t } = useTranslation()

  return (
    <Typography variant="caption" color="text.secondary">
      {t('CreatedAt')}: {format(parseISO(createdAt), 'yyyy年MM月dd日HH時mm分')}
    </Typography>
  )
}
