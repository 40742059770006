import { Tooltip, Typography } from '@mui/material'

export type PhysicianProps = {
  physician?: {
    physicianID: string
    fullName: string
  }
}

export function Physician({ physician }: PhysicianProps) {
  if (!physician) {
    return null
  }

  return (
    <Tooltip title={physician.physicianID} placement="top">
      <Typography component="span">{physician.fullName}</Typography>
    </Tooltip>
  )
}
