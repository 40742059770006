import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

export type UpdatedAtProps = {
  updatedAt: string
}

export function UpdatedAt({ updatedAt }: UpdatedAtProps) {
  const { t } = useTranslation()

  return (
    <Typography variant="caption" color="text.secondary">
      {t('UpdatedAt')}: {format(parseISO(updatedAt), 'HH時mm分')}
    </Typography>
  )
}
