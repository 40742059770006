import { Typography } from '@mui/material'
import { getDepartmentFromCode } from '../../../common/utils/reservation'

export type DepartmentProps = {
  departmentCode: string
}

export function Department({ departmentCode }: DepartmentProps) {
  return (
    <Typography component="span">{getDepartmentFromCode(departmentCode).displayName}</Typography>
  )
}
